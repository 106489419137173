import React from 'react'
import {
  ChartBarSquareIcon as ChartBarSquareOutline,
  CreditCardIcon as CreditCardOutline,
  BookOpenIcon as BookOpenOutline,
  DocumentChartBarIcon as DocumentChartOutline,
  QueueListIcon as QueueListOutline,
  UserGroupIcon as UserGroupOutline,
  BeakerIcon as BeakerOutline,
  UserIcon as UserOutline,
  SparklesIcon as SparklesOutline,
  BoltIcon as BoltOutline
} from '@heroicons/react/24/outline'

import {
  ChartBarSquareIcon as ChartBarSquareSolid,
  CreditCardIcon as CreditCardSolid,
  BookOpenIcon as BookOpenSolid,
  DocumentChartBarIcon as DocumentChartSolid,
  QueueListIcon as QueueListSolid,
  UserGroupIcon as UserGroupSolid,
  BeakerIcon as BeakerSolid,
  UserIcon as UserSolid,
  SparklesIcon as SparklesSolid,
  BoltIcon as BoltSolid
} from '@heroicons/react/24/solid'

const iconMap = {
  chart_square: { solid: ChartBarSquareSolid, outline: ChartBarSquareOutline },
  credit_card: { solid: CreditCardSolid, outline: CreditCardOutline },
  book_open: { solid: BookOpenSolid, outline: BookOpenOutline },
  document_report: { solid: DocumentChartSolid, outline: DocumentChartOutline },
  collection: { solid: QueueListSolid, outline: QueueListOutline },
  user_group: { solid: UserGroupSolid, outline: UserGroupOutline },
  beaker: { solid: BeakerSolid, outline: BeakerOutline },
  user: { solid: UserSolid, outline: UserOutline },
  sparkles: { solid: SparklesSolid, outline: SparklesOutline },
  bolt: { solid: BoltSolid, outline: BoltOutline },
}

const NavigationIcon = ({ icon, isSelected, className = "w-7 h-7" }) => {
  const iconKey = icon.split('/')[1]
  const icons = iconMap[iconKey] || { solid: BoltSolid, outline: BoltOutline }
  const Icon = isSelected ? icons.solid : icons.outline
  
  return <Icon className={className} />
}

export default NavigationIcon