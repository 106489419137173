import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="legacy-navigation"
export default class extends Controller {
  connect() {
  }

  select(event) {
    let links = this.element.querySelectorAll("a")
    links.forEach((link) => {
      link.classList.remove("nav-selected-tab")
      link.classList.add("nav-unselected")
    })

    let link = event.target.closest("a")
    link.classList.remove("nav-unselected")
    link.classList.add("nav-selected-tab")
  }
}