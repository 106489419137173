import React from 'react'
import NavigationIcon from './navigation_icon'

const MobileNavigation = ({ links, onSelect }) => {
  const handleTouchStart = (e, href) => {
    const element = e.currentTarget;
    element.classList.add('ripple');
    element.addEventListener('animationend', () => {
      element.classList.remove('ripple');
    }, { once: true });
    onSelect(e, href);
  };

  return (
    <div className="touch-none h-14 border-t border-zinc-900 w-full flex flex-row md:hidden">
      {links.map((link) => (
        <a
          key={link.name}
          className={`nav-link relative overflow-hidden ${
            link.selected ? 'nav-selected-tab' : 'nav-unselected'
          }`}
          onClick={(e) => handleTouchStart(e, link.href)}
          onTouchStart={(e) => handleTouchStart(e, link.href)}
          href={link.href}
          data-turbo-frame="main"
          data-turbo-action="advance"
        >
          <div className="relative">
            {link.badge_count > 0 && (
              <span 
                style={{ top: '-4px', right: '-10px' }}
                className="absolute items-center rounded-full bg-rose-800 px-1.5 ml-1 py-0.5 text-xs font-medium text-zinc-300"
              >
                {link.badge_count}
              </span>
            )}
            <div className="mt-1 w-fit h-fit flex items-center justify-center">
              <NavigationIcon icon={link.icon} isSelected={link.selected} />
            </div>
          </div>
          <span className="nav-label -mt-1">{link.name}</span>
        </a>
      ))}
    </div>
  )
}

export default MobileNavigation 